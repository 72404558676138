<template>
<div class="mainform">
    <div class="form">
        <el-form ref="form" class="content" :model="form" label-width="170px" :disabled="false">
            <el-form-item label="编码">
                <el-input v-model="form.baseCode"></el-input>
            </el-form-item>
            <el-form-item label="基地名称">
               <el-input v-model="form.baseName"></el-input>
            </el-form-item>
            <el-form-item label="地址">
                <el-input v-model="form.baseAddr"></el-input>
            </el-form-item>
            <el-form-item label="联系人">
                <el-input v-model="form.baseHead"></el-input>
            </el-form-item>
            <el-form-item label="联系电话">
                <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="form.remark"></el-input>
            </el-form-item>
        </el-form>
    </div>
</div>
</template>

<script>
import Component from '@/assets/js/components.js'
export default {
  name: 'EditProductionBase',
  components: Component.components,
  props: ['formData'],
  data: function () {
    return {
      form: this.formData
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  .mainform{
    background: none;
    padding: 0;
    .form{
      background: none;
      .content{
        padding: 0px 0;
      }
    }
  }
.mainform .form .el-form .el-form-item:last-child {
    margin-bottom: 0px;
}
</style>
